<template>
  <div v-if="dataLoaded">
    <v-container class="pa-0">
      <app-view-toolbar
        title="Scener"
        :showBackButton="true"
        :buttons="toolbarButtons"
        @onButton="onToolbarButton">
      </app-view-toolbar>
    </v-container>
    <v-container class="py-2 px-6">
      <v-data-table
        :headers="tableData.headers"
        :items="tableData.items"
        :items-per-page="itemsPerPage"
        no-data-text="Inga scener finns upplagda"
        no-results-text="Inga scener hittades"
        :page.sync="page"
        @click:row="onOpenUpdate"
        @page-count="pageCount = $event"
        hide-default-footer
        class="click-table"
        locale="sv-SE">
      </v-data-table>
    </v-container>
  </div>
</template>
  
<script>
import { onMounted, reactive, ref } from '@vue/composition-api';
import useCore from '../../helpers/core';
import ViewToolbar from '../../components/ViewToolbar.vue'

export default {
  name: 'eventStageList',
  components: {
    'app-view-toolbar': ViewToolbar,
  },
  setup() {
    const { callApi } = useCore();
    const router = require('@/router/router').default;

    const dataLoaded = ref(false);
    const tableData = reactive({
      headers: [{ text: "Namn", value: "est_str_name" }],
      items: [],
    });

    // Actions
    const onOpenCreate = () => {
      router.push({ name: 'eventStagePut', params: { id: 0 } });
    };
    const onOpenUpdate = (eventStage) => {
      router.push({ name: 'eventStagePut', params: { id: eventStage.est_id } });
    };
    
    // Toolbar actions
    const toolbarButtons = [{ icon: 'plus', name: 'Ny scen', fn: onOpenCreate }];
    const onToolbarButton = (button) => button.fn();

    // Lifecycle-methods
    const doOnMounted = () => {
      callApi({ method: 'get', path: '/cms/events/stages' })
        .then((res) => {
          dataLoaded.value = true;
          tableData.items = res;
        })
        .catch((err) => {
          return err;
        });
    };
    onMounted(doOnMounted);

    return {
      dataLoaded,
      tableData,
      page: 1,
      pageCount: 0,
      itemsPerPage: 100,
      toolbarButtons,
      onToolbarButton,
      onOpenUpdate,
    };
  },
};
</script>

<style>
</style>